import React, { useContext, useState } from 'react'
import { UserContext } from '../Context/Authcontext'


export default function Home() {

    const {user, stopLossPips, setStopLossPips,takeProfitPips, setTakeProfitPips,stopLossPrice, setStopLossPrice,
        takeProfitPrice, setTakeProfitPrice,entryPrice, setEntryPrice,tradePair, setTradePair,totalRisk, setTotalRisk,totalLotSize, setTotalLotSize,
        NumberOfTrade, setNumberOfTrade,RiskPerTrade, setRiskPerTrade,lotSizePerTrade, setLotSizePerTrade,trade, settrade,
        newStopLossvalue, setNewStopLossvalue, newEntryPrice ,setNewEntryPrice 
       } = useContext(UserContext)

    const [showform, setShowForm] = useState(false)

    const calculateTopLossPips = () => {
       
        if(!entryPrice && !stopLossPrice) return alert("Please Enter Price ")

            if( entryPrice.length  === stopLossPrice.length  ){
                    console.log('i reach here');       
                    const calc = entryPrice - stopLossPrice 
                        setStopLossPips(Math.floor(Math.abs(calc*10000)))

            }else{
               setStopLossPips("Incorrect Number")

            }
            // if( numOfDecimalDigits(entryPrice) === 4  && numOfDecimalDigits(stopLossPrice) === 4 ){
            //         console.log('i reach here');       
            //         const calc = entryPrice - stopLossPrice 
            //             setStopLossPips(Math.floor(Math.abs(calc*10000)))

            // }else if(numOfDecimalDigits(entryPrice) === 2 && numOfDecimalDigits(stopLossPrice) === 2){
            //     console.log('i reach her3432'); 
            //     const calc = entryPrice - stopLossPrice 
            //     setStopLossPips(Math.floor(Math.abs(calc*100)))

            // }else{
            //    setStopLossPips("Incorrect Number")

            // }
    }


   const calculateTotalLotSize = () => {
         if(!totalRisk && !stopLossPips) return alert("Kindly calculate Stop loss and enter your risk value ")
        let totallotsize = (totalRisk / stopLossPips) /100

        setTotalLotSize(totallotsize)
    }

    const  calculateNewStopLoss= () => {
        const riskpertrade = totalRisk / NumberOfTrade
         setRiskPerTrade(riskpertrade)
         let lotsizepertrade = totalLotSize / NumberOfTrade
         setLotSizePerTrade(lotsizepertrade)

       const newstoplossvalue = ((RiskPerTrade *10) - newEntryPrice ) / lotSizePerTrade
       setNewStopLossvalue(newstoplossvalue)
    }
     



    const numOfDecimalDigits = (number) => {
        // Convert to number and check if it's valid
        const num = parseFloat(number);
        
        if (!isNaN(num)) {
            console.log(!isNaN(num));
            let decimalPart = num.toString().split('.')[1]; // Get the part after the decimal
            let numberOfValues = decimalPart ? decimalPart.length : 0; // Count the digits
    
            return numberOfValues;
        } else {
            return 0;
        }
    };


    
  return (
    <div>
        {/* Navbar */}
        <nav className="navbar fixed-top  text-white p-2" style={{backgroundColor:"#301935"}}>
        <div className="container-fluid">
            <div className='text-center'>
                  <h4 className=''>TradeResponse</h4>
              </div>
      
        </div>
    </nav>
    <br/> <br/> <br/> <br/> <br/>
   
    <div className='conatiner-fluid'>
         <div className='row'>
            <div className='col-sm-12 col-md-3 col-lg-3'></div>
            <div className='col-sm-12 col-md-4 col-lg-4'>
                 <h5>Pip Calculating Tool</h5>
                 <br/>
                 <input
                        className='p-2 m-2'
                        placeholder='Entry Price'
                        onChange={(e) => setEntryPrice(e.target.value ? parseFloat(e.target.value) : null)}
                    />
                    <input
                        className='p-2 m-2'
                        placeholder='SL Price'
                        onChange={(e) => setStopLossPrice(e.target.value ? parseFloat(e.target.value) : null)}
                    />
                 <input className='p-2 m-2' placeholder='Take Profit Price' onChange={(e)=>setTakeProfitPrice(e.target.value)}></input>
                 <input className='p-2 m-2' placeholder='Total Risk($)' onChange={(e)=>setTotalRisk(e.target.value)}></input>
                 
                  <br/> <br/>
                  {stopLossPips && <p><b>Stop Loss Pips </b> = {stopLossPips} pips</p> } 
                   <br/>
                   {totalLotSize && <p><b>Total Lot Size of ${totalRisk} is </b> = {totalLotSize} lot size</p> } 
                   <br/>
                 <button
                      type="submit"
                      class="btn btn-primary m-1 font-monospace"
                      onClick ={calculateTopLossPips}
                    >
                      Calculate Pips
                    </button>
                    
                     <button
                       onClick={calculateTotalLotSize}
                      type="submit"
                      class="btn btn-primary m-1 font-monospace "
                    >
                     Calculate Total Size
                    </button>

                    <br/> <br/> 
                    <p> 
                           <h5>
                             <input
                        type='checkbox'
                        onChange={()=>setShowForm(!showform)}
                            
                        /> 
                        want to calcate the new Stop Loss PRICE
                           </h5>
                           
                    </p>
                    <br/>
                    {showform && (
                        <div>
                           <input
                        className='p-2 m-2'
                        placeholder='Enter Number of trade'
                        onChange={(e) => setNumberOfTrade(e.target.value ? parseFloat(e.target.value) : null)}
                          /> 
                       
                       <input
                        className='p-2 m-2'
                        placeholder='Enter New Entry Price'
                        onChange={(e) => setNewEntryPrice(e.target.value ? parseFloat(e.target.value) : null)}
                       />
                    
                    {newStopLossvalue && <p><b>New stop loss price is</b> = {newStopLossvalue} </p> } 

                            <button
                            onClick={calculateNewStopLoss}
                            type="submit"
                            class="btn btn-danger m-1 font-monospace "
                            >
                            Calculate New Stop Loss
                            </button>
                        </div>
                    )}
                
            </div>

            

                   
            <div className='col-sm-12 col-md-4 col-lg-4'></div>

         </div>
    </div>




      
    </div>
  )
}
