import React,{ createContext, useEffect, useState} from "react";
import ApiServices from "../Services/ApiServices";


export const UserContext = createContext();

export const  UserUpdateContext = createContext()


function UserContextProvider({children}) {

        const[user, setUser] = useState(null)
        const [stopLossPips, setStopLossPips] = useState(null);
        const [takeProfitPips, setTakeProfitPips] = useState(0);
        const [stopLossPrice, setStopLossPrice]  = useState(0);
        const [takeProfitPrice, setTakeProfitPrice] = useState(0);
        const [entryPrice, setEntryPrice] = useState(0)
        const [tradePair, setTradePair] = useState(0);
        const [totalRisk, setTotalRisk] = useState(0);
        const [totalLotSize, setTotalLotSize] = useState(null);
        const [NumberOfTrade, setNumberOfTrade] = useState(0);
        const [RiskPerTrade, setRiskPerTrade] = useState(0);
        const [lotSizePerTrade, setLotSizePerTrade] = useState(0)
        const [trade, settrade] = useState('')
        const [newStopLossvalue, setNewStopLossvalue] = useState(null)
        const [newEntryPrice ,setNewEntryPrice ] = useState(null)
    // const user =ApiServices.getUserCookie("user")

     return ( 

       <UserContext.Provider value={{user, stopLossPips, setStopLossPips,takeProfitPips, setTakeProfitPips,stopLossPrice, setStopLossPrice,
        takeProfitPrice, setTakeProfitPrice,entryPrice, setEntryPrice,tradePair, setTradePair,totalRisk, setTotalRisk,totalLotSize, setTotalLotSize,
        NumberOfTrade, setNumberOfTrade,RiskPerTrade, setRiskPerTrade,lotSizePerTrade, setLotSizePerTrade,trade, settrade,
        newStopLossvalue, setNewStopLossvalue, newEntryPrice ,setNewEntryPrice 
       }}>

         {/* <UserUpdateContext.Provider > */}
            {children}
         {/* </UserUpdateContext.Provider> */}
       </UserContext.Provider>
     );
}

export default UserContextProvider;







